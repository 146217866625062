import React from "react";

import {
  Container,
  Button,
  Typography,
  // Customizable Area Start
  Grid
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import TextField from "@material-ui/core/TextField";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import RequestDemoController, {
  Props,
} from "./RequestDemoController";
import { Modal } from "react-native";
import Loader from "../../../components/src/Loader";

export default class RequestDemo extends RequestDemoController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth="sm">
        <Typography variant="h4" align="center" gutterBottom>
        </Typography>
        {/* <form onSubmit={this.handleSubmit}> */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                data-test-id={"firstName"}
                label="First Name"
                name="firstName"
                value={this.state.firstName}
                onChange={(event) => { this.handleChangeFirstName(event.target.value) }}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                data-test-id={"lastName"}
                label="Last Name"
                name="lastName"
                value={this.state.lastName}
                onChange={(event) => { this.handleChangeLastName(event.target.value) }}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                data-test-id={"email"}
                label="Email"
                name="email"
                type="email"
                value={this.state.email}
                onChange={(event) => { this.handleChangeEmail(event.target.value) }}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                data-test-id={"phone"}
                label="Contact #"
                name="phone"
                value={this.state.phone}
                onChange={(event) => { this.handleChangePhone(event.target.value) }}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                data-test-id={"countryPicker"}
                label="Location"
                name="location"
                select
                value={this.state.location}
                onChange={(event) => { this.handleChangeLocation(event.target.value) }}
                fullWidth
                required
              >
                {this.state.countriesData.map((country) => (
                  <option key={country.name} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                data-test-id={"servicePicker"}
                label="Type of service requested"
                name="serviceRequested"
                select
                value={this.state.serviceType}
                onChange={(event) => { this.handleChangeServiceType(event.target.value) }}
                fullWidth
                required
              >
              {this.state.servicesData.map((service) => (
                <option key={service.id} value={service.id}>
                  {service.service_name}
                </option>
              ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                data-test-id={"datePicker"}
                label="Date"
                name="date"
                type="date"
                value={this.state.date}
                onChange={(event) => { this.handleChangeDate(event.target.value) }}
                fullWidth
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                data-test-id={"timeSlotPicker"}
                label="Time Slot"
                name="timeSlot"
                select
                value={this.state.timeSlot}
                onChange={(event) => { this.handleChangeTimeSlot(event.target.value) }}
                fullWidth
                required
              >
              {this.state.timeSlotsData.map((slot) => (
                <option key={slot.id} value={slot.id}>
                  {this.getTimeFromDateTime(slot.start_time)} - {this.getTimeFromDateTime(slot.end_time)}
                </option>
              ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Button data-test-id={"submitBtn"} onClick={this.handleSubmit} type="submit" variant="contained" color="default" fullWidth>
                Submit
              </Button>
            </Grid>
          </Grid>
        {/* </form> */}
        <Modal visible={this.state.loader}>
          <Loader loading={this.state.loader}/>
        </Modal>
      </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
